export const environment = {
  firebase: {
    apiKey: 'AIzaSyCcV84r4XGmP18xI_wernzBzxme2H_X3ko',
    authDomain: 'vagus-tech-production.firebaseapp.com',
    projectId: 'vagus-tech-production',
    storageBucket: 'vagus-tech-production.appspot.com',
    messagingSenderId: '627833919576',
    appId: '1:627833919576:web:bc47e179199a1dd979122d',
    measurementId: 'G-H642S2FEVT',
    rechaptchaToken: '6Lc3OGIoAAAAABR4phNY_Va3JwAieHWoqmerpGLH',
    appCheckDebugToken: false,
  },
  version: require('../../package.json').version,
  production: true,
  useEmulators: false,
  name: 'production',
  sentryDsn:
    'https://c479dc94a7f0419f98092f69380b6e98@o4505522962759680.ingest.sentry.io/4505522965905408',
  apiUrl: 'https://app.finapsys.cl/api',
};
